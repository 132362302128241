<!--
 * @Description: 导入价格体系
 * @Author: WuPuyi
 * @Date: 2021-03-19 11:58:21
 * @LastEditors: LiangYiNing
 * @LastEditTime: 2022-10-14 16:30:01
-->
<template>
  <div class="upload-center content-view-wrapper pd-10">
    <div
      class="content-view"
      v-loading="loading"
      ref="sectionWrapper"
      :element-loading-text="pageLoadingText"
    >
      <div class="edit-wrapper">
        <div class="edit-wrapper__body">
          <div class="body-content">
            <el-row class="edit-title-div fixed-section">
              <el-col :span="14">
                <div class="edit-title">
                  {{ "导入信息" }}
                  <span class="title-tip">
                    <i class="iconfont e6-icon-abnormal_line"></i>
                    请务必下载模板填写，以便成功导入信息
                  </span>
                </div>
                <!--上传组件 start-->
                <div class="uploader-wrapper">
                  <el-button
                    class="download-btn"
                    type="primary"
                    plain
                    @click="getInitInfo"
                  >
                    下载模板
                  </el-button>

                  <el-upload
                    class="uploader"
                    ref="uploader"
                    accept=".xls,.xlsx"
                    :show-file-list="false"
                    :auto-upload="false"
                    :multiple="false"
                    name="multipartFile"
                    :action="uploadAction"
                    :on-change="uploadOnChange"
                  >
                    <el-button type="primary">
                      点击上传
                    </el-button>
                  </el-upload>
                  <span class="tips">只能上传.xls/.xlsx文件</span>
                </div>
              </el-col>
            </el-row>

            <!--解析结果表格 start-->
            <div class="table-wrapper">
              <el-table
                v-if="analysisLists.length > 0"
                :data="tableData"
                ref="analysisTable"
                border
                stripe
                highlight-current-row
                :rowHeight="37"
                :height="resizeViewHeight - 55 + 'px'"
                useVirtual
                style="width: 100%"
                :row-key="
                  row => {
                    return row.excelRowId;
                  }
                "
              >
                <!--    @select="handleSelectionChanged"
                @select-all="handleAllSelectChanged" -->
                <!-- <el-table-column
                  type="selection"
                  fixed
                  align="center"
                  width="50"
                  reserve-selection
                  :selectable="isRowSelectable"
                ></el-table-column> -->
                <el-table-column
                  label="序号"
                  type="index"
                  fixed
                  align="center"
                  width="50"
                ></el-table-column>
                <el-table-column
                  v-if="isImported"
                  label="导入状态"
                  align="center"
                  width="90"
                >
                  <template slot-scope="{ row }">
                    <span
                      v-if="row.code === 'OK'"
                      class="import-status success"
                    >
                      成功
                    </span>
                    <span
                      v-else-if="row.code === -1"
                      class="import-status pending"
                    >
                      上传中...
                    </span>
                    <el-popover
                      v-else-if="row.code === 0"
                      placement="top"
                      trigger="hover"
                    >
                      <p class="fail-reason-content">{{ row.message }}</p>
                      <span slot="reference" class="import-status fail">
                        失败
                      </span>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="isImported"
                  label="导入失败原因"
                  prop="message"
                  header-align="center"
                  width="130"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  v-for="(col, idx) in columns"
                  :label="col"
                  :prop="col"
                  min-width="110"
                  header-align="center"
                  show-overflow-tooltip
                  :key="`${col}-${idx}`"
                ></el-table-column>
                <el-table-column
                  fixed="right"
                  align="center"
                  label="操作"
                  width="220"
                  class-name="td-operate"
                >
                  <template
                    slot-scope="{ row, $index }"
                    v-if="isRowSelectable(row)"
                  >
                    <el-button type="text" @click="handleTableData(row, 1)">
                      地址列表
                    </el-button>
                    <el-button type="text" @click="handleTableData(row, 2)">
                      物料列表
                    </el-button>
                    <el-button
                      type="text"
                      @click="handleEditResult(row, $index)"
                    >
                      修改
                    </el-button>
                    <el-button type="text" @click="handleDeleteResult(row)">
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!--解析结果表格 end-->
            <!-- 分页 start -->
            <section
              class="pagination-wrapper fixed-section"
              v-if="analysisLists.length > 0"
            >
              <el-pagination
                :page-size.sync="searchForm.pageSize"
                :current-page.sync="searchForm.pageIndex"
                :page-sizes="pageSizes"
                :layout="layout"
                :total="analysisLists.length"
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              ></el-pagination>
            </section>
          </div>
        </div>
        <div class="edit-wrapper__footer  fixed-section">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="handleImport">导入</el-button>
        </div>
      </div>

      <el-dialog
        v-dialogDrag
        title="修改"
        :visible.sync="editVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="600px"
        custom-class="edit-dialog"
      >
        <div class="edit-form-wrapper">
          <el-form ref="editAnalysisForm" label-width="120px">
            <template v-for="(item, idx) in curEditList">
              <el-form-item
                v-if="!notShowKeys.includes(item[0])"
                :label="item[0]"
                :key="idx"
              >
                <el-input v-model.trim="item[1]"></el-input>
              </el-form-item>
            </template>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button class="cancel" @click="editVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="handleSaveEditAnalysis">
            确认修改
          </el-button>
        </div>
      </el-dialog>
    </div>
    <upload-dialog
      :uploadVisible.sync="uploadVisible"
      :detailTableData="detailTableData"
      :detailColumns="detailColumns"
    ></upload-dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";
import listPage from "@/mixins/list-page";
import base from "@/mixins/base";
import {
  printError,
  formatDate,
  getKeyValueFromRouterParams
} from "@/utils/util";
import { importServicePrice, getUploadCenterInitInfo } from "@/api";
import uploadDialog from "./uploadDialog.vue";

export default {
  components: { uploadDialog },
  name: "uploadXlsx",
  mixins: [base, listPage],
  data() {
    return {
      excelRowIdMap: new Map(),
      pageLoadingText: "",
      searchForm: { pageSize: 100, pageIndex: 1 },
      columns: [], // 解析结果表格列数据
      analysisLists: [], // 上传文件解析结果列表
      fileIsParsed: false, // 文件是否已被解析
      isImported: false, // 是否已经导入(是否展示导入状态/失败原因列)
      editVisible: false, // 修改解析结果弹框
      notShowKeys: ["excelRowId", "code", "message"], // 编辑弹框不展示的字段
      curEditList: [], // 当前修改的行数据（数组）
      maxRequest: 0, // 最大同时上传个数
      curEditIndex: null, // 当前修改的行索引
      // 最大的http并发请求
      maxReq: 10,
      firstExcel: null,
      secondExcel: null,
      thirdExcel: null,
      uploadVisible: false,
      detailTableData: [],
      detailColumns: [],
      workbook: {}
    };
  },

  computed: {
    // 前端分页逻辑
    tableData() {
      let { pageIndex, pageSize } = this.searchForm;
      return this.analysisLists.slice(
        pageSize * (pageIndex - 1),
        pageSize * pageIndex
      );
    },
    // 动态下载模板引入参数
    dynamicTemplate: vm =>
      getKeyValueFromRouterParams(vm, "dynamicTemplate") ?? "",
    // 最大单个http的数据量
    maxDataSize: vm =>
      parseInt(getKeyValueFromRouterParams(vm, "maxDataSize")) || 20,
    // 文件上传路径
    uploadAction: () =>
      `${process.env.VUE_APP_HOST_API || ""}/api/E3-BASE-FILE-UPLOAD/file/parse`
  },
  created() {},

  activated() {
    if (this.$route.params.refresh) {
      this.refreshData();
    }
  },

  methods: {
    // 获取模块名称/模板下载路径/导入时业务方接口
    async getInitInfo() {
      try {
        this.pageLoadingText = "数据正在加载中...";
        this.loading = true;
        let res = await getUploadCenterInitInfo({
          templateKey: "service_price_import"
        });
        let downloadIframe = document.createElement("iframe");
        downloadIframe.setAttribute("src", res.data.templateUrl || "");
        downloadIframe.setAttribute("style", "display: none");
        document.body.appendChild(downloadIframe);
      } catch (err) {
        printError(err);
      } finally {
        this.loading = false;
      }
    },

    // 添加文件后对文件进行读取，这里读取两个sheet中的数据
    async uploadOnChange(ev) {
      let file = ev.raw;
      if (this.lastUid == file.uid) {
        return;
      }
      this.lastUid = file.uid;
      if (file) {
        if (
          file.name.indexOf(".xls") != -1 ||
          file.name.indexOf(".xlsx") != -1
        ) {
          //这里进行了判断，只能读取这两种类型的文件。
          let data = await this.readFile(file);
          let workbook = XLSX.read(data, {
            type: "binary"
            // cellDates: true
          }); //解析二进制格式数据
          this.workbook = workbook;
          //获取的sheet的名称  服务价格表,服务地址表,服务物料分类表
          let firstsheet = workbook.Sheets["服务价格表"]; //获取第一个Sheet
          let secondsheet = workbook.Sheets["服务地址表"]; //获取第二个Sheet
          let thirdsheet = workbook.Sheets["服务物料分类表"]; //获取第三个Sheet
          let firstExcel = XLSX.utils.sheet_to_json(firstsheet, {
            defval: ""
          });
          firstExcel.map(item => {
            item["开始生效时间"] = formatDate(item["开始生效时间"]);
            item["结束时间"] = formatDate(item["结束时间"]);
          });
          this.firstExcel = firstExcel;
          this.analysisLists = firstExcel;
          this.secondExcel = XLSX.utils.sheet_to_json(secondsheet, {
            defval: ""
          });
          this.thirdExcel = XLSX.utils.sheet_to_json(thirdsheet, {
            defval: ""
          });
          this.columns = this.handleColumns("服务价格表", this.firstExcel);
          this.excelDataToJson(this.firstExcel);
        }
      }
    },
    readFile(file) {
      //文件读取
      return new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsBinaryString(file); //以二进制的方式读取
        reader.onload = ev => {
          resolve(ev.target.result);
        };
      });
    },
    //处理表头数组
    handleColumns(wsname, list) {
      let titleList = [];
      let workbook = this.workbook;
      //第一页表头
      for (let key in workbook.Sheets[wsname]) {
        titleList.push(workbook.Sheets[wsname][key]);
      }
      let titleListRes = [];
      // 用于提供给标题过滤试用
      const forHelpRowData = list[0];
      if (titleList && titleList.length) {
        titleList.map(title => {
          if (forHelpRowData.hasOwnProperty(title.v)) {
            titleListRes.push(title.v + "");
          }
        });
      } else {
        for (let key in forHelpRowData) {
          titleListRes.push(key);
        }
      }
      return titleListRes;
    },
    // 表格数据转换
    excelDataToJson(list = []) {
      if (!list || !list.length) {
        this.$message.error("未解析出数据");
        return;
      }
      let res = [];
      list.map(item => {
        let effective = false; // 用于判断数据是否有效 判断标准：任意一个关键字段有值即为有效，空格不算
        for (let key in item) {
          item[key] += ""; // 统一转化成字符串，方便处理
          item[key] = item[key].trim() || null;
          effective = effective || item[key];
        }
        // 有效数据保存
        if (effective) {
          item.excelRowId = res.length + 1;
          res.push(item);
        }
      });
      if (!res.length) {
        this.$message.error("未解析出数据或数据无效");
        return;
      }
      this.isImported = false; // 隐藏导入状态/失败原因列
      this.fileIsParsed = true; // 文件是否被解析
      this.searchForm.pageIndex = 1; // 重置分页
      this.analysisLists = res; // 显示表格数据
      this.excelRowIdMap = new Map();
      res.forEach(v => this.excelRowIdMap.set(v.excelRowId, v));
      this.resizeListener();
    },
    // 上传时回调
    uploadProgress() {
      this.pageLoadingText = "数据正在解析中...";
      this.loading = true;
    },
    // 解析结果当前行是否可选中(是否可导入)
    isRowSelectable(row) {
      return row.code !== "OK" && row.code !== -1;
    },
    handleTableData(row, type) {
      if (type == 1) {
        //地址列表
        this.detailColumns = this.handleColumns("服务地址表", this.secondExcel);
        this.detailTableData = this.secondExcel.filter(
          item => item["飞书合同编号"] == row["飞书合同编号"]
        );
      } else {
        //物料列表
        this.detailColumns = this.handleColumns(
          "服务物料分类表",
          this.thirdExcel
        );
        this.detailTableData = this.thirdExcel.filter(
          item => item["飞书合同编号"] == row["飞书合同编号"]
        );
      }
      this.uploadVisible = true;
    },
    // 重置页面数据
    refreshData() {
      this.columns = [];
      this.analysisLists = [];
      this.fileIsParsed = false;
      this.isImported = false;
      this.editVisible = false;
      this.curEditList = [];
      this.curEditIndex = null;
    },
    // 批量导入选中数据
    async batchImportAnalysisResult(rowsData) {
      try {
        this.pageLoadingText = "数据正在导入中...";
        this.isImported = true; // 展示导入状态/失败原因列
        // 设置数据状态
        rowsData.forEach(v => {
          this.$set(v, "code", -1);
          this.$set(v, "message", "");
        });
        this.submitDataByMaxDataSize([rowsData]);
      } catch (err) {
        printError(err);
      }
    },
    // 提交数据，先限制每一个HTTP请求的最大数据量
    async submitDataByMaxDataSize(dataArr) {
      if (!Array.isArray(dataArr) || !dataArr.length) return;
      const { maxDataSize, submitDataGroup } = this;
      while (dataArr.length) {
        // 拿出一组数据
        const group = dataArr.pop();
        // 将这组数据再分组，满足最大dataSize的限制
        const groupCollection = [];
        while (group.length) groupCollection.push(group.splice(0, maxDataSize));
        // 发送分组数据
        await submitDataGroup(groupCollection);
      }
    },
    // 提交分组数据，在这一步对http的并发进行限制
    async submitDataGroup(dataArr) {
      if (!Array.isArray(dataArr) || !dataArr.length) return;
      try {
        const { maxReq, submitData } = this;
        while (dataArr.length) {
          const group = dataArr.splice(0, maxReq);
          // 使用await暂停while循环，达到并发分组的目的
          await Promise.all(group.map(v => submitData(v)));
        }
      } catch (e) {
        printError(e);
      }
    },
    //处理提交的数据格式
    handleImport() {
      let analysisLists = _.cloneDeep(this.analysisLists);
      let newArr = [];
      analysisLists.map(item => {
        let obj = {};
        obj.excelRowId = item.excelRowId;
        obj["服务价格"] = { ...item };
        obj["服务地址"] = this.secondExcel.filter(
          ele => ele["飞书合同编号"] == item["飞书合同编号"]
        );
        obj["服务物料价格"] = this.thirdExcel.filter(
          ele => ele["飞书合同编号"] == item["飞书合同编号"]
        );
        newArr.push(obj);
      });
      this.batchImportAnalysisResult(newArr);
    },
    // 提交数据
    async submitData(data) {
      if (!Array.isArray(data) || !data.length) return;
      try {
        let param = data;
        let res = await importServicePrice(param);
        const { code, data: result, message } = res;
        if (code === "OK") {
          result.forEach(rowRes => {
            // 用返回数据中的excelRowId字段查找表格数据analysisLists中对应行索引idx
            const excelRowId = rowRes.data
              ? rowRes.data.excelRowId
              : rowRes.excelRowId;
            if (!excelRowId) return;
            const dataItem = this.excelRowIdMap.get(excelRowId);
            if (!dataItem) return;
            // 这里统一下code 失败的都为0
            this.$set(dataItem, "code", rowRes.code === "OK" ? "OK" : 0);
            this.$set(dataItem, "message", rowRes.message || rowRes.msg);
          });
        } else {
          // 设置数据状态为失败
          data.forEach(v => {
            this.$set(v, "code", 0);
            this.$set(v, "message", message);
          });
        }
      } catch (err) {
        printError(err);
      }
    },
    // 修改解析结果
    handleEditResult(row, index) {
      let curEditData = _.cloneDeep(row);
      this.curEditList = [];
      this.curEditList.push(["excelRowId", curEditData.excelRowId]);
      if (curEditData.code || curEditData.code === 0) {
        this.curEditList.push(["code", curEditData.code]);
      }
      if (curEditData.message) {
        this.curEditList.push(["message", curEditData.message]);
      }
      // 按表头顺序排序
      this.columns.forEach(item => {
        this.curEditList.push([item, curEditData[item]]);
      });
      this.curEditIndex = index;
      this.editVisible = true;
    },
    // 删除解析结果
    handleDeleteResult(row) {
      this.$confirm("确定删除本条数据？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: "info"
      })
        .then(() => {
          // 找到数据下标
          const idx = this.analysisLists.indexOf(row);
          if (idx !== -1) {
            this.analysisLists.splice(idx, 1);
            this.excelRowIdMap.delete(row.excelRowId);
            // 处理一下分页逻辑 当前页无数据时返回上一页
            let { pageIndex, pageSize } = this.searchForm;
            if (this.analysisLists.length <= (pageIndex - 1) * pageSize) {
              this.searchForm.pageIndex = pageIndex > 1 ? pageIndex - 1 : 1;
            }
          }
          // 在选中项中也删除
          const selIdx = this.selections.indexOf(row);
          if (selIdx !== -1) {
            this.selections.splice(selIdx, 1);
          }
        })
        .catch(err => {
          printError(err);
        });
    },
    // 确认修改
    handleSaveEditAnalysis() {
      let curEditData = {};
      this.curEditList.forEach(item => {
        curEditData[item[0]] = item[1];
      });
      let rowData = _.cloneDeep(curEditData);
      this.$set(this.analysisLists, this.curEditIndex, rowData);
      // 修改索引对应的值
      this.excelRowIdMap.set(rowData.excelRowId, rowData);
      this.editVisible = false;
      var index = this.selections.findIndex(item => {
        return item.excelRowId === rowData.excelRowId;
      });
      if (index !== -1) {
        // 删除旧数据，并插入新数据
        this.selections.splice(index, 1, rowData);
        // 设置表格选中新数据
        this.$refs.analysisTable.toggleRowSelection(rowData, true);
      }
    },

    // 返回上一页
    handleGoBack() {
      //关闭当前Tag
      this.closeTag(this.$route);
      //   this.routerPush({
      //     name: this.originRouteName,
      //     params: {
      //       refresh: true
      //     }
      //   });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
@import "~@/assets/styles/variables.scss";
.upload-center {
  .body-content {
    padding: 0 20px;
    height: calc(100% - 40px);
    .edit-title-top {
      padding-top: 20px;
    }
    .edit-title-div {
      padding: 20px 0 10px 0;
    }
  }
  .edit-title {
    padding-left: 0;
    border: none;
    background: none;
    .title-tip {
      margin-left: 15px;
      font-size: 14px;
      font-weight: normal;
      color: #909399;
    }
  }
  .uploader-wrapper {
    position: relative;
    /deep/.uploader {
      display: inline-block;
      margin-left: 100px;
      .el-upload-list {
        margin-left: -100px;
      }
    }
    .tips {
      font-size: 12px;
      margin-left: 10px;
      color: #909399;
      position: absolute;
      left: 180px;
      top: 8px;
    }
    .tips_warning {
      color: $--color-status--assist-1;
      margin-left: 10px;
      font-size: 12px;
      display: inline-block;
      position: relative;
      bottom: 8px;
    }
  }
  .upload-tip {
    display: inline-block;
    width: 555px;
    // float: right;
    p {
      font-size: 14px;
      color: #909399;
      line-height: 22px;
    }
    .warning {
      color: $--color-status--assist-1;
    }
  }
  .download-btn {
    position: absolute;
    left: 0;
  }
  .table-wrapper {
    // margin-top: 10px;
    // height: calc(100% - 117px);
    /deep/ .el-table {
      .el-table__header {
        thead th .cell {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .el-button--small {
        padding: 5px 0;
      }
    }
    .import-status {
      display: inline-block;
      padding: 0 10px;
      line-height: 23px;
      border-radius: 3px;
      cursor: pointer;
      color: #ffffff;
      &.success {
        background-color: $--color-status--assist-2;
      }
      &.fail {
        background-color: $--color-status--assist-3;
      }
      &.pending {
        color: #909399;
      }
    }
  }
  /deep/ .edit-dialog {
    .edit-form-wrapper {
      padding: 20px;
      max-height: 450px;
      overflow: auto;
    }
  }
}
.fail-reason-content {
  padding: 0 10px;
  font-size: 12px;
}
</style>
